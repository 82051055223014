:root {
  --blueberry: #002a3a;
  --eggshell: #ffffff;
  --wafer: #d6cdc6;
  --yolk: #ebeb4d;
  --asparagus: #8bb7a2;
}

@font-face {
  font-family: QuincyCF-Regular;
  src: url("./fonts/QuincyCF-Regular.otf");
}

@font-face {
  font-family: QuincyCF-Light;
  src: url("./fonts/QuincyCF-Light.otf");
}

@font-face {
  font-family: MaisonNeue-Book;
  src: url("./fonts/MaisonNeue-Book.otf");
}


html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::selection {
  background-color: var(--blueberry);
  color: var(--wafer);
}

body {
  color: var(--blueberry);
  background-color: var(--blueberry);
  padding: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
}


a:link {
  color: var(--blueberry);
}

a:visited {
  color: var(--asparagus);
}


.menu {
  padding: 2rem 4rem 1rem;
  margin: 0 auto;
  max-width: 50rem;
  background-color: var(--wafer);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

@media (min-width: 35rem) {
  .menu {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr;
  }

}

.menu-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  text-align: center;
}

.logo {
  color: var(--blueberry);
  background-color: var(--wafer);
  width: 100%;
  max-width: 150px;
  margin: 2rem auto;
  display: block;
  grid-column: 1 / 3;
}

@media (min-width: 35rem) {

  .logo {
    margin-bottom: 0;
  }

}

.menu-section {
  grid-column: 1 / 3;
}

.menu-section-title {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 2.5rem;
  font-family: QuincyCF-Regular;
}

.item-name {
  margin: 0;
  flex-grow: 1;
  font-size: 1rem;
  font-family: MaisonNeue-Medium;
}

.item-description {
  flex: 1 1 100%;
  margin: 0.5rem 0 0;
  color: var(--blueberry);
  font-family: MaisonNeue-Book;
  font-size: 1rem;
}

.item-price {
  flex: 1 1 100%;
  margin: 0.5rem 0 0;
  color: var(--blueberry);
  font-family: MaisonNeue-Book;
}

@media screen and (min-width: 35rem){

    .menu-section-title{
        margin-top: -2rem
    }

}

@media screen and (min-width: 35rem){


    .menu-footer {
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        font-size: 0.5rem!important
    }

    .menu-footer-right {
        text-align: right;
    }
}

.menu-footer {
  font-size: 0.35rem;
  font-family: QuincyCF-Regular;
  text-align: center;
  /*display: flex;*/
  grid-column: 1 / 3;
}

.menu-footer-left {
  /*width: 50%;*/
}

.menu-footer-right {
  /*text-align: right;*/
  /* flex-grow: 1; */
}
